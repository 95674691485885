import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import api from '@/utils/api'
import { survicatePlugin } from './plugins/survicate'
import { launchDarklyPlugin } from './plugins/launchDarkly'

import auth from './modules/auth'
import stores from './modules/stores'
import applications from './modules/applications'
import pricing from './modules/pricing'
import calculator from './modules/calculator'
import events from './modules/events'
import ui from './modules/ui'
import secondOfferPrice from './modules/second-offer-price'
import language from './modules/language'
import leads from './modules/leads'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'kafene-v2',
  reducer: (state) => ({
    auth: {
      token: state.auth.token,
      user: state.auth.user,
      isLoggedIn: state.auth.isLoggedIn
    },
    events: {
      merchantAccess: state.events.merchantAccess
    },
    adminMessage: state.adminMessage,
    adminMessageUrgency: state.adminMessageUrgency,
    adminMessageExpiresAt: state.adminMessageExpiresAt,
    ui: {
      appEnvironment: ui.appEnvironment
    }
  })
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appLoadingStatus: false,
    formAutofill: false,
    alternateSigning: false,
    cardStep: false,
    support: [],
    adminMessage: '',
    adminMessageId: '',
    adminMessageUrgency: '',
    adminMessageExpiresAt: '',
    adminEmail: null,
    fundingReports: [],
    teamPerformance: null,
    pageData: {}
  },
  mutations: {
    setAppLoadingStatus: (state, payload) => {
      state.appLoadingStatus = payload
    },
    setFormAutofill: (state, payload) => {
      state.formAutofill = payload
    },
    setAlternateSigning: (state, payload) => {
      state.alternateSigning = payload
    },
    setCardStep: (state, payload) => {
      state.cardStep = payload
    },
    updateSupport: (state, payload) => {
      state.support = payload
    },
    setAdminMessage: (state, payload) => {
      state.adminMessage = payload
    },
    setAdminMessageId: (state, payload) => {
      state.adminMessageId = payload
    },
    setAdminMessageUrgency: (state, payload) => {
      state.adminMessageUrgency = payload
    },
    setAdminMessageExpiresAt: (state, payload) => {
      state.adminMessageExpiresAt = payload
    },
    setAdminEmail: (state, payload) => {
      state.adminEmail = payload
    },
    setFundingReports: (state, payload) => {
      state.fundingReports = payload
    },
    setTeamPerformance: (state, payload) => {
      state.teamPerformance = payload
    },
    setPageData: (state, payload) => {
      state.pageData = payload
    },
    setEmployeePermissions: (state, payload) => {
      state.auth.user.user_permissions = payload
    }
  },
  actions: {
    async fetchPageData({ commit }) {
      try {
        const response = await api.get('/')
        commit('setPageData', response.data.data.field_data)
        return Promise.resolve(true)
      } catch (error) {
        return error
      }
    },
    async fetchEmployee({ commit }) {
      try {
        const employee_id = this.state.auth.user.employee_id
        if (employee_id) {
          const response = await api.get(`/merchants/employees/${employee_id}`)
          const permissions = response.data.data.employee_data.user_permissions

          if (permissions) {
            commit('setEmployeePermissions', permissions)
          }
          return Promise.resolve(true)
        }
      } catch (error) {
        return error
      }
    },
    fetchSupportDetails: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        api
          .get('dashboard/merchant-sales-support-details', data)
          .then(({ data }) => {
            const {
              data: { merchant_sales_support_data }
            } = data
            commit('updateSupport', merchant_sales_support_data)
            resolve(data)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    fetchFundingReport: ({ commit }) => {
      return new Promise((resolve, reject) => {
        api
          .get('dashboard/merchant-funding-report')
          .then(({ data }) => {
            const {
              data: { merchant_sales_support_data }
            } = data
            commit('setFundingReports', merchant_sales_support_data.merchantFundingReport)
            resolve(data)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    fetchFilteredReport: (params, payload) => {
      return new Promise((resolve, reject) => {
        api
          .post('dashboard/get-merchant-report', payload)
          .then(({ data }) => {
            const { data: merchantFundingReport } = data.data
            resolve(merchantFundingReport)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    downloadFundingReport: (context, payload) => {
      return new Promise((resolve, reject) => {
        api
          .post('dashboard/generate-report-csv', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    fetchFilteredLeaseDetails: (params, payload) => {
      return new Promise((resolve, reject) => {
        api
          .post('dashboard/get-lease-details', payload)
          .then(({ data }) => {
            const { data: leaseDetails } = data.data
            const response = leaseDetails.flatMap((lease) => lease)
            resolve(response)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    downloadLeaseDetails: (context, payload) => {
      return new Promise((resolve, reject) => {
        api
          .post('dashboard/get-lease-details', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    fetchTeamPerformanceData: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        api
          .post('dashboard/team-performance-report', payload)
          .then(({ data }) => {
            commit('setTeamPerformance', data.data)
            resolve(data.data)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    },
    downloadTeamPerformanceReport: (context, payload) => {
      return new Promise((resolve, reject) => {
        api
          .post('dashboard/export-team-performance-report', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            if (err.response) {
              reject(err.response.data.message)
            } else if (err.request) {
              reject(err.request)
            } else {
              reject(err.message || err)
            }
            reject(err)
          })
      })
    }
  },
  getters: {
    getAppLoadingStatus: (state) => state.appLoadingStatus,
    getFormAutofill: (state) => state.formAutofill,
    getAlternateSigning: (state) => state.alternateSigning,
    getCardStep: (state) => state.cardStep,
    getSupportData: (state) => state.support,
    getAdminMessage: (state) => state.adminMessage,
    getAdminMessageId: (state) => state.adminMessageId,
    getAdminMessageUrgency: (state) => state.adminMessageUrgency,
    getAdminMessageExpiresAt: (state) => state.adminMessageExpiresAt,
    getAdminEmail: (state) => state.adminEmail,
    getFundingReports: (state) => state.fundingReports,
    getTeamPerformance: (state) => state.teamPerformance,
    getPageData: (state) => state.pageData
  },
  modules: {
    auth,
    stores,
    pricing,
    calculator,
    applications,
    events,
    ui,
    secondOfferPrice,
    language,
    leads
  },
  plugins: [vuexLocal.plugin, survicatePlugin, launchDarklyPlugin(process.env.VUE_APP_LAUNCH_DARKLY_API_KEY)]
})
