export default {
  name: 'KToggle',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleToggle(event) {
      if (!this.disabled) {
        event.stopPropagation()
        event.preventDefault()
        this.$emit('input', !this.value)
      }
    }
  }
}
